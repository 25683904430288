import React, { useState } from 'react';

export const ChatContext = React.createContext();
export const ChatProvider = ({ children }) => {
    const [prompts, setPrompts] = useState([]);
    const [selected, setSelected] = useState("McKinsey");
  
    return (
      <ChatContext.Provider value={{ prompts, setPrompts, selected, setSelected }}>
        {children}
      </ChatContext.Provider>
    );
  };