import React, { useState } from 'react';
import styles from "./InterviewPageStartMbpro.module.css";
import { Messages} from '../components/Messages';
import ChatCard from '../components/ChatCard';
import StateComp from '../components/StateComp';
import styled from 'styled-components';

const InterviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    background-color: #F2EFE4;
    min-height: 75vh;
    height: 100vh;
    max-height: 100vh;
`;

const NavBar = styled.div`
    position: fixed;
    top: 0; 
    margin-bottom: 10px;
    width: 100%;
    background-color: white;
    z-index: 1000;
    border: 1px solid #ccc;
`;

const Body = styled.div`
    margin-top: 100px;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
`;

const InterviewPage = () => {

    return(
        <ChatCard />
    )
}

export default InterviewPage;