import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: left;
    width: 90%;
    min-width: 90%;
    gap:10px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #0d1117;
    border-bottom: 1px solid #2F3640;
    font-family: var(--font-open-sans);
`;

const UserTextRow = styled.div`
    display: flex;
    align-items: center;
    min-width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    padding: 10px;
`;

const UserBox = styled.div`
    text-decoration: none;
    padding: var(--padding-11xs) 0 4px;
    justify-content: space-between;
    z-index: 1;
    text-align: center;
    font-size: var(--font-size-17xl);
    color: inherit;
    background-color: #2F3640;
    font-family: var(--font-open-sans);
    border: 1px solid #2F3640;
    border-radius: 30%;
    width: 40px;
    height: 40px;
    font-weight: 600;
`;

const TextBox = styled.div`
    flex: 1;
    position: relative;
    width: 100%;
`;

const Icon = styled.div`
    position: relative;
    color: #8390FA;
    background-color: transparent;
`;


const Message = ({ message }) => {
    const divRef = useRef(null);

    useEffect(() => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
      });

    return (
    <Container ref={divRef}>
        <UserTextRow>
        <UserBox><Icon>{message.role == "user" ? "U" : "AI"}</Icon></UserBox>
        <TextBox><p>{message.content}</p></TextBox>
        </UserTextRow>
    </Container>
    );
};

export default Message;