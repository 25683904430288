// ChatCard.js
import React, { useState, useContext } from 'react';
import {Messages} from './Messages';
import { sendGPTMessage } from '../api/openai';
import { ChatContext } from '../ChatContext';
import { Container, NavBar, MessageContainer, CompanyTitle, CompanySelect, UserInput, HistoryButton, HistoryDiv, Button, Input, Send, Form } from './ChatCardStyled';
import styles from "../pages/InterviewPageStartMbpro.module.css";
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';


const options = ["McKinsey", "Deloitte", "BCG", "Bain", "KPMG"];

const ChatCard = () => {
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { prompts, setPrompts, selected, setSelected } = useContext(ChatContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const KeyValue = {};
    KeyValue["role"] = "user";
    KeyValue["content"] = inputMessage;
    setInputMessage('');
    
    setPrompts((prevPrompts) => [...prevPrompts, KeyValue]);
    
    const data = {
      company: selected,
      messages: [...prompts, KeyValue]
    }
    const response = await sendGPTMessage(data);
    setIsLoading(false);
    
    const KeyValue2 = {
      role: "assistant",
      content: response.data
    };
  
    setPrompts((prevPrompts) => [...prevPrompts, KeyValue2]);
    
    setInputMessage('');
  };



  return (
    <Container>
      <NavBar>
            <a
                className={styles.headerGrouping}
                target="_blank"
                href="/homepage-mbpro-14"
            >
                <div className={styles.logo}>
                <i className={styles.interviewai}>AthenaInterviews</i>
                </div>
            </a>
            <HistoryButton onClick={() => navigate('/history')}>
               History
            </HistoryButton>

      </NavBar>
      <MessageContainer>
      <CompanyTitle>{selected}</CompanyTitle>
        <form>
        <CompanySelect 
          value={selected} 
          onChange={e => setSelected(e.target.value)}>
          {options.map((value) => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
        </CompanySelect>
        </form>
        <Messages prompts={prompts} />
      </MessageContainer>
      <UserInput>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <Button type="submit" disabled={isLoading}>
            <SendIcon />
          </Button>
        </Form >
      </UserInput>
    </Container>
  );
};

export default ChatCard;
