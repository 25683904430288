import React, { useContext, useState } from 'react';
import { ChatContext } from '../ChatContext';
import styled from "styled-components";
import styles from "./InterviewPageStartMbpro.module.css";
import { useNavigate } from 'react-router-dom';
import { evalMessage } from '../api/openai';

const Container = styled.div`
  align-items: left;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #F2EFE4;
  padding-top: 100px;
`;

const NavBar = styled.div`
    position: fixed;
    top: 0; 
    margin-bottom: 10px;
    width: 100%;
    background-color: white;
    z-index: 1000;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px 20px; // Increase padding-right
`;

const HistoryButton = styled.button`
    margin-left: auto;
    background-color: #f8f9fa;
    border: none;
    padding: 10px 20px;
    margin-right: 30px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    &:hover {
      background-color: #e8e9ea;
    }
`;

const EvaluateButton = styled.button`
    background-color: #f8f9fa;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    &:hover {
        background-color: #e8e9ea;
    }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:10px;
  top: 50vh;
  height: 80vh;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 50px;
  padding-top:10vh;
  overflow-y: scroll;
`;

const PrettyJSON = styled.pre`
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    color: #333;
    font-family: 'Courier New', Courier, monospace;
`;


const Output = styled.pre`

`;

const PreformattedText = styled.p`
  white-space: pre-line;
`;

const History = () => {
  const { prompts, selected } = useContext(ChatContext);
  const navigate = useNavigate();
  const [evaluation, setEvaluation] = useState("");
  const handleSubmit = async () => {
    const data = {
      company: selected,
      messages: prompts
    }
    const response = await evalMessage(data);
    console.log(response.data)
    setEvaluation(response.data);
  };

  const formatText = (text) => {
    return text.split('\n').map((line, index) => {
      return (
        <React.Fragment key={index}>
          {line}
          <br/>
        </React.Fragment>
      );
    });
}


  return (
    <Container>
        <NavBar>
            <a
                    className={styles.headerGrouping}
                    target="_blank"
                    href="/homepage-mbpro-14"
                >
                    <div className={styles.logo}>
                    <i className={styles.interviewai}>AthenaInterviews</i>
                    </div>
            </a>
            <HistoryButton onClick={() => navigate('/')}>
               Back
            </HistoryButton>

        </NavBar>
        <MessageContainer>
        <h1>Evaluate Interview</h1>
        <PreformattedText>{evaluation}</PreformattedText>
            <EvaluateButton onClick={()=>handleSubmit()}>
                Evaluate
            </EvaluateButton>
        </MessageContainer>
        
    </Container>  
  );
}

export default History;