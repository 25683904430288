import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import InterviewPageStartMbpro from "./pages/InterviewPageStartMbpro";
import InterviewPage from "./pages/InterviewPage";
import HomepageMbpro14 from "./pages/HomepageMbpro14";
import History from "./pages/History.js";
import { useEffect } from "react";
import { ChatProvider } from "./ChatContext";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/homepage-mbpro-14":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <ChatProvider>
      <Routes>
        <Route path="/" element={<InterviewPage />} /> 
        {/* <Route path="/" element={<InterviewPageStartMbpro />} /> */}
        <Route path="/homepage-mbpro-14" element={<HomepageMbpro14 />} />
        <Route path="/history" element={<History />} />
      </Routes>
    </ChatProvider>
  );
}
export default App;
