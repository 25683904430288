import styled from 'styled-components';


export const Container = styled.div`
  align-items: left;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  min-height: 90vh;
  background-color: #0d1117;
  color: white;
  padding-top: 100px;
  overflow-y: scroll;
  padding-bottom: 20px;
  /* border: 10px solid black; */
`;

export const NavBar = styled.div`
    position: fixed;
    top: 0; 
    margin-bottom: 10px;
    width: 100%;
    background-color: #0d1117;
    z-index: 1000;
    border-bottom: 1px solid #8390FA;
    border-radius: 5px;
    color: #8390FA;
    display: flex;
    align-items: center;
    padding: 10px 20px; // Increase padding-right
`;



export const HistoryDiv = styled.div`
  display: flex;
  right: 0;
`

export const HistoryButton = styled.button`
    margin-left: auto;
    background-color: #2F3640;
    color: #8390FA;
    border: none;
    padding: 10px 20px;
    margin-right: 30px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    &:hover {
      background-color: #8390FA;
      color: #0d1117;
    }
`;



export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  height: 80vh;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 50px;
  padding-top: 50px; // Reduce padding-top to accommodate the messages better.
  overflow: auto; // Add scroll in case the messages overflow.
  /* border: 10px solid red; */
`;

export const CompanyTitle = styled.h3`
  display: flex;
  color: #8390FA;
`;

export const CompanySelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 5vh;
  min-width: 30%;
  border: 1px solid black;
  padding-top: 5px;
  background-color: #2F3640;
`;

export const CompanySelect = styled.select`
  min-width: 7vw;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: #2F3640;
  border: 1px solid #2F3640;
  color: #8390FA;
  border-radius: 5px;
`;


export const Input = styled.input`
  flex-grow: 1;
  border: none;
  background-color: transparent;
  color: white;
  &:focus {
    outline: none;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 5px;
  border-radius: 3px;
  background-color: #2F3640;
  margin: 0 auto;
`;

export const Button = styled.button`
  border: none;
  background-color: #0d1117;
  border-radius: 4px;
  :hover {
    background-color: #8390FA;
  }
  :active {
    background-color: #8390FA;
  }
`;





export const UserInput = styled.div`
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background-color: rgba(13, 17, 23, 0.8); // 80% opacity
`;
