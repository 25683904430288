// MessageCard.js
import React, { useState } from 'react';
import Message from './Message';
import styled from 'styled-components';

const Container = styled.div`
  align-items: left;
  justify-content: center;
  width: 100%;
  min-height: 90%;
`;

const Messages = ({prompts}) => {

  return (
    <Container>
      {prompts.map((prompt, index) => (
        <Message key={index} message={prompt} />
      ))}
    </Container>
    
  );
};

export { Messages };