// src/api.js
import axios from 'axios';

// const produrl = "http://127.0.0.1:5000"
const produrl = "https://athenainterviews.herokuapp.com"

export const sendGPTMessage = async (data) => {
    console.log("data", data)
    const response = await axios.post(`${produrl}/messages`, data);
    return response
};

export const evalMessage = async (data) => {
    console.log("data", data)
    const response = await axios.post(`${produrl}/evaluate`, data);
    return response
}

