import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HomepageMbpro14.module.css";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .button:hover {
    background-color: white;
  }
`;

const HomepageMbpro14 = () => {
  const navigate = useNavigate();

  const onTryInterviewButtonMoreTypClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.homepageMbpro14}>
      <div className={styles.startingScreen}>
        <div className={styles.backgroundTopSlide} />
        <div className={styles.headerGrouping}>
          <i className={styles.interviewai}>AthenaInterviews</i>
        </div>
        <div className={styles.homepageTitle}>
          <b className={styles.aiPoweredConsultingIntervie}>
            AI-Powered Consulting Interview Prep
          </b>
        </div>
        <div className={styles.homepageSubtitle}>
          <div className={styles.aiPoweredConsultingIntervie}>
            Our AI Helps You™
        </div>
        </div>
        <ButtonContainer>
        <button
              className={styles.tryInterviewButtonMoreTyp}
              onClick={onTryInterviewButtonMoreTypClick}
            >
              <div className={styles.text3}>Try Interview</div>
          </button>
          </ButtonContainer>
        </div>
      </div>
  );
};

export default HomepageMbpro14;
